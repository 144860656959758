import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { ErrorMsg, ErrorBtn, DataList, MyDataGrid } from "../Pacientes/styles";
import { BsEyeFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import { AceitarButton, RecusarButton } from "./styled";
import { toast } from "react-toastify";
import PageContainer from "../../../Components/PageContainer";
import Tabela from "../../../Components/Tabela";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";

export default function Pendentes() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState("");
  const [repositoriesData, setRepositoriesData] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);

  async function handleStatusChange(id, novoStatus) {
    try {
      await api.patch(`/user/${id}/status`, { status: novoStatus });
      console.log(handleStatusChange);
      fetchPendingUsers();
      const showToastMessage = () => {
        toast.success(`Sucesso ao alterar status do prestador `, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessage();
    } catch (error) {
      const showToastMessageError = () => {
        toast.error(`Não foi possivel completar a operação`, {
          position: toast.POSITION.TOP_CENTER,
        });
      };
      showToastMessageError();
      console.log(error);
    }
  }

  const columns = [
    { field: "nome", headerName: "Nome", width: 250 },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 0.5,
      valueGetter: (params) => {
        if (params.row.tipo === "medico") {
          return "Médico";
        } else if (params.row.tipo === "clinica") {
          return "Clinica";
        } else {
          return "Vendedor";
        }
      },
    },
    {
      field: "aceitar",
      headerName: "Aceitar",
      flex: 0.5,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<AceitarButton>Aceitar</AceitarButton>}
          label="Aceitar"
          onClick={() => handleStatusChange(params.id, "Active")}
        />
      ),
    },
    {
      field: "recusar",
      headerName: "Recusar",
      flex: 0.5,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<RecusarButton>Recusar</RecusarButton>}
          label="Recusar"
          onClick={() => handleStatusChange(params.id, "Suspended")}
        />
      ),
    },
    {
      field: "visualizar",
      type: "actions",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BsEyeFill size={20} color="#7A99E3" />}
          onClick={() => {
            history.push(`/pendentes/${params.id}`);
          }}
          label="Visualizar"
        />,
      ],
    },
  ];

  const fetchPendingUsers = async () => {
    setLoading(true);
    setErrorRepositories(null);

    try {
      const pendentes = await api.get("/users?filter=status;eq;Pending");
      const mergedData = [...pendentes.data];
      console.log(pendentes.data);
      setRepositoriesData(mergedData);
      setPendingUsers({ pendentes });
    } catch (error) {
      setErrorRepositories("Erro ao buscar usuários pendentes");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Pendentes</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={(event) => refresh(event)}>
            Tentar novamente
          </ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Pendentes</PageTitle>
          <ReactLoading
            type="spin"
            color="#B2EBF2"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      <PageContainerDiv>
      <Background>
      <RightDiv>
      
        <PageTitle>Pendentes</PageTitle>
        <Tabela rows={repositoriesData} columns={columns} />
        </RightDiv>
      </Background>
      </PageContainerDiv>
    </Page>
  );
}
