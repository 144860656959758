import React, { useEffect, useState } from "react";
import api from "../../../Services/api";
import { useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import SidebarMenu from "../../../Components/SidebarMenu";
import { Page } from "../../SignedOut/Cadastro/styles";
import { PageTitle } from "../CadastrarMedico/styles";
import { ErrorMsg, ErrorBtn, DataList, MyDataGrid } from "../Pacientes/styles";
import { BsEyeFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import PageContainer from "../../../Components/PageContainer";
import Tabela from "../../../Components/Tabela";
import { Background, PageContainerDiv, RightDiv } from "../Perfil/styles";

export default function Prestadores() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorRepositories, setErrorRepositories] = useState(null);
  const [repositoriesData, setRepositoriesData] = useState([]);

  const columns = [
    { field: "nome", headerName: "Nome", width: 250 },
    { field: "telefone", headerName: "Telefone", flex: 1 },
    { field: "cpf", headerName: "CPF", flex: 1 },

    {
      field: "visualizar",
      type: "actions",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BsEyeFill size={20} color="#7A99E3" />}
          onClick={() => history.push(`/prestadores/${params.id}`)}
          label="Visualizar"
        />,
      ],
    },
  ];

  useEffect(() => {
    const fetchRepositoriesData = async () => {
      setLoading(true);
      setErrorRepositories(null);

      try {
        const response = await api.get("/medicos/listar", {
          params: { status: "Active" },
        });
        console.log("respostaaaa", response);
        const repositories = response.data.medicos
          .filter((item) => item.status === "Active")
          .map((item) => ({
            id: item.id,
            nome: item.nome,
            email: item.email,
            tipo: item.tipo,
            cpf: item.medico.cpf,
            crm: item.medico.crm,
            telefone: item.medico.telefone,
            creditos: item.medico.creditos,
          }));
        setRepositoriesData(repositories);
      } catch (error) {
        setErrorRepositories("Ocorreu um erro ao carregar a página");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRepositoriesData();
  }, []);

  function refresh() {
    window.location.reload();
  }

  if (errorRepositories) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Prestadores</PageTitle>
          <ErrorMsg>{errorRepositories}</ErrorMsg>
          <ErrorBtn onClick={(event) => refresh(event)}>
            Tentar novamente
          </ErrorBtn>
        </PageContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <SidebarMenu />
        <PageContainer>
          <PageTitle>Prestadores</PageTitle>
          <ReactLoading
            type="spin"
            color="#067187"
            height={"30px"}
            width={"30px"}
          />
        </PageContainer>
      </Page>
    );
  }

  return (
    <Page>
      <SidebarMenu />
      
      <PageContainerDiv>
        <Background>
        <RightDiv>
        <PageTitle>Prestadores</PageTitle>
        <Tabela rows={repositoriesData} columns={columns} />
        </RightDiv>
        </Background>
        </PageContainerDiv>
      
    </Page>
  );
}
